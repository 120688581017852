import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'
import hamburger from '@nickel/ui/components/icons/HamburgerMenu'

import { InactivityWatcher } from '../../components/InactivityWatcher'
import { NavBar } from '../components/NavBar'

import Styled from './Home.styles'

export const Home = (props: { lang: string }) => {
    const { t } = useTranslation()
    const [showNavBar, setShowNavBar] = React.useState(false)

    const subscriptionLink = `${window.REACT_APP_WELCOME_URL}/${props.lang}?${window.REACT_APP_WELCOME_QUERY_PARAM}`

    const burgerClick = () => {
        setShowNavBar(!showNavBar)
    }
    const sleepUrl = `/${props.lang}/sleep`

    return (
        <Styled.Section>
            <InactivityWatcher url={sleepUrl}>
                {window.REACT_APP_IS_MENU_ACTIVE === 'true' && (
                    <>
                        <Styled.MenuButton>
                            <Button Icon={hamburger} testId="" text="" variant="secondary" onClick={burgerClick} />
                        </Styled.MenuButton>
                        <NavBar lang={props.lang} isShown={showNavBar} handleClick={burgerClick} />
                    </>
                )}
                <Styled.NickelImage>
                    <div>
                        <img src="/images/Logo.svg" alt="Logo nickel" />
                    </div>
                </Styled.NickelImage>
                <h1>
                    {t('home:titleStart')}
                    <span style={{ color: '#ff5f00' }}>{t('home:titleMiddle')}</span>
                    {t('home:titleEnd')}
                </h1>
                <a href={subscriptionLink}>
                    <Styled.LinkBox>
                        <img src="/images/Nickel-Classic-image.png" alt="Carte basic Nickel" />
                        <h1>{t('home:accountOpenning')}</h1>
                    </Styled.LinkBox>
                </a>
                {window.REACT_APP_IS_FAQ_ACTIVE === 'true' && (
                    <Styled.LinkBox>
                        <a href={window.REACT_APP_FAQ_URL}>
                            <div>
                                <img src="/images/FAQ-image.png" alt="FAQ" />
                                <h1>{t('home:faq')}</h1>
                            </div>
                        </a>
                    </Styled.LinkBox>
                )}
            </InactivityWatcher>
        </Styled.Section>
    )
}

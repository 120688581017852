import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'
import ExitTopRight from '@nickel/ui/components/icons/ExitTopRight'
import House from '@nickel/ui/components/icons/House'
import Cross from '@nickel/ui/components/icons/X'

import Styled from './NavBar.styles'

export const NavBar = (props: { lang: string; isShown: boolean; handleClick: () => void }) => {
    const partnerLink = `${window.REACT_APP_PARTNER_TRAINNING_URL}/${props.lang}?${window.REACT_APP_PARTNER_TRAINNING_QUERY_PARAM}`
    const home = `/${props.lang}`
    const legal = `/${props.lang}/legal`
    const { t } = useTranslation()

    return (
        <div>
            {props.isShown && (
                <Styled.Section>
                    <div>
                        <div>
                            <Styled.Menu>
                                <h2>
                                    {t('menu:menu')}
                                    <Button
                                        Icon={Cross}
                                        testId=""
                                        text=""
                                        variant="secondary"
                                        onClick={props.handleClick}
                                    />
                                </h2>
                            </Styled.Menu>
                        </div>
                        <Styled.Home>
                            <h2>
                                <a href={home}>
                                    <House />
                                    {t('menu:home')}
                                </a>
                            </h2>
                        </Styled.Home>
                        <Styled.Divider />
                        <Styled.Category>{t('menu:assistance')}</Styled.Category>
                        <Styled.Documents>
                            <h2>
                                <a href={legal}>{t('menu:legalDocuments')}</a>
                            </h2>
                        </Styled.Documents>
                        <Styled.Divider />
                        {window.REACT_APP_IS_PARTNER_TRAINNING_ACTIVE === 'true' && (
                            <Styled.Formation>
                                <Styled.Category>{t('menu:pointOfSale')}</Styled.Category>
                                <h2>
                                    <a href={partnerLink}>{t('menu:trainning')}</a>
                                    &nbsp;
                                    <ExitTopRight />
                                </h2>
                            </Styled.Formation>
                        )}
                    </div>
                </Styled.Section>
            )}
        </div>
    )
}

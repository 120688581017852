import React, { MutableRefObject } from 'react'

import { useNavigate } from 'react-router-dom'

const eventToListen: string[] = ['pointerover', 'pointermove', 'touchstart', 'touchmove']

export const InactivityWatcher = (props: { url: string; children: React.ReactNode }) => {
    const timeoutRef: MutableRefObject<NodeJS.Timeout | null> = React.useRef(null)
    const navigate = useNavigate()
    React.useEffect(() => {
        const restartTimeout = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                navigate(props.url)
            }, 1000 * (parseInt(window.REACT_APP_SLEEP_TIMER, 10) || 30))
        }
        restartTimeout()
        eventToListen.map((_: string) => window.addEventListener(_, restartTimeout))
        return () => {
            eventToListen.map((_: string) => window.removeEventListener(_, restartTimeout))
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [navigate, props.url])
    return <div>{props.children}</div>
}
